import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export const ScMobileMenuHeight = '70px';

export const ScMobileSideBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${ScMobileMenuHeight};
  background: ${({ theme }) => theme.colors['layer-01']};
  border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 110;
  box-sizing: border-box;
  .sidebar-mobile-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: 20px;
    align-items: center;
    color: ${({ theme }) => theme.colors['text-primary']};
    background: none;
    border: 0;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
    font: ${({ theme }) => theme.typography['label-x-small']};
    @media (${device.mobileXXL}) {
      margin: ${({ theme }) => theme.spacing.primitives['s-04']};
    }
    &.active {
      color: ${({ theme }) => theme.colors['text-brand']} !important;
      svg *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.colors['icon-brand']};
      }
      svg *[class$='svg-stroke'] {
        stroke: ${({ theme }) => theme.colors['icon-brand']};
      }
    }
    svg {
      margin: 0 auto 5px auto;
      *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.colors['icon-secondary-strong']};
      }
      *[class$='svg-stroke'] {
        stroke: ${({ theme }) => theme.colors['icon-secondary-strong']};
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

export const ScMoreBlock = styled.div`
  position: absolute;
  z-index: 99;
  background: ${({ theme }) => theme.colors['layer-01']};
  width: 100%;
  height: auto;
  max-height: calc(100vh - ${ScMobileMenuHeight});
  bottom: ${ScMobileMenuHeight};
  padding: 30px 30px 50px 30px;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};

  border-radius: 8px 8px 0 0;
  @media (max-height: 471px) {
    padding: 10px 30px 30px 30px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    font-weight: 300;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
  }
  hr {
    margin: 12px 0 12px auto;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
    @media (max-height: 471px) {
      margin: 5px 0 5px 0;
    }
  }
  .header-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    @media (max-height: 471px) {
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    }
    a,
    button {
      color: ${({ theme }) => theme.colors['text-subtle']};
      margin-bottom: 25px;
      text-align: left;
      font-size: 14px;
      @media (max-height: 471px) {
        margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sidebar-mobile-link {
    flex-direction: row;
    justify-content: left;
    margin: 25px auto;
    width: auto;
    @media (max-height: 471px) {
      margin: 15px auto;
    }
    span {
      font-size: 16px;
      @media (max-height: 471px) {
        font-size: 14px;
      }
    }
    svg {
      margin: 0 5px 0 0;
    }
  }
`;
