import React, { FC, PropsWithChildren, useEffect } from 'react';

import * as Sentry from '@sentry/react';

import { FORBIDDEN_ERROR_CODE, useApi } from 'hooks/api';

import { isAuthenticated as isAuthenticatedRequest } from 'api/auth';

import { InternalErrorBlock } from 'components/internalError';
import { Loader } from 'components/loader';

import { ForbiddenPage } from 'modules/forbiddenPage';

import { setAuthService } from 'services/authService';

import { AuthServiceContext } from './context';
import { AuthService } from './service';

export const AuthServiceProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [
    checkAuthentication,
    {
      data: isAuthenticated,
      pending: authenticationCheckPending,
      error: authenticationCheckError,
      errorMessage,
      errorData: authenticationCheckErrorData,
    },
  ] = useApi(isAuthenticatedRequest, { pending: true });

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    if (authenticationCheckError != null) {
      Sentry.captureMessage(
        `AUTH REQUEST FAILED\nCode: ${authenticationCheckError}\nMessage: ${errorMessage}`,
        'fatal'
      );
    }
  }, [authenticationCheckError, errorMessage]);

  if (authenticationCheckPending) {
    return <Loader />;
  }

  if (authenticationCheckError === FORBIDDEN_ERROR_CODE) {
    return (
      <ForbiddenPage
        ip={authenticationCheckErrorData.ip}
        countryCode={authenticationCheckErrorData.countryCode}
      />
    );
  }

  if (authenticationCheckError != null || isAuthenticated == null) {
    return (
      <section>
        <InternalErrorBlock />
      </section>
    );
  }

  const authService = new AuthService(isAuthenticated);

  // makes auth service accessible for non react tree code
  setAuthService(authService);

  return (
    <AuthServiceContext.Provider value={authService}>
      {children}
    </AuthServiceContext.Provider>
  );
};
