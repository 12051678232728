import * as Sentry from '@sentry/react';

import i18next, { i18n } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { fetchLanguageByIP } from 'api/geo';

export const availableLng = ['ru', 'en', 'uk', 'pl'];
export const fallbackLng = 'en';

export const languagesInfo: {
  [key: string]: { name: string; code: string };
} = {
  ru: {
    name: 'Русский',
    code: 'ru',
  },
  en: {
    name: 'English',
    code: 'en',
  },
  uk: {
    name: 'Українська',
    code: 'uk',
  },
  pl: {
    name: 'Polski',
    code: 'pl',
  },
};

class CustomLanguageDetector extends I18nextBrowserLanguageDetector {
  async = true;
  detect(setLng: any) {
    const detectedLng = super.detect();

    if (detectedLng && detectedLng?.length) {
      setLng(detectedLng);
    } else {
      fetchLanguageByIP().then(setLng);
    }
    return undefined;
  }
}

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,
  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

i18next.on('failedLoading', function (lng, ns, msg) {
  Sentry.captureMessage(
    `Failed to load i18n resource ${lng}.${ns}\nMsg: ${msg}`,
    'warning'
  );
});

export default i18next
  .use(CustomLanguageDetector)
  .use(HttpApi)
  .use(I18nInstanceSetter as any)
  .use(initReactI18next)
  .init({
    fallbackLng,
    supportedLngs: availableLng,
    backend: {
      loadPath: `${
        process.env.NODE_ENV !== 'development' && process.env.PUBLIC_URL
          ? process.env.PUBLIC_URL
          : ''
      }/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: process.env.BUILD_VERSION },
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage'],
      lookupLocalStorage: 'lng',
      caches: ['localStorage', 'cookie'],
    },
    ns: ['common', 'glossary', 'validation'],
    defaultNS: 'common',
    debug: false,
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
