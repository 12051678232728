import { useEffect, useRef, useState } from 'react';

import throttle from 'lodash.throttle';

import { useMutable } from 'hooks/common';

export function useComponentVisible(initialIsVisible: boolean) {
  const ref = useRef<any>(null);
  const openerRef = useRef<any>(null);

  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      const checkClickOutsideElementAndOpener =
        ref.current != null &&
        openerRef.current != null &&
        !ref.current.contains(event.target) &&
        !openerRef.current.contains(event.target);

      const checkClickOutsideElement =
        openerRef.current == null &&
        ref.current != null &&
        !ref.current.contains(event.target);

      if (checkClickOutsideElementAndOpener || checkClickOutsideElement) {
        setIsComponentVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return { ref, openerRef, isComponentVisible, setIsComponentVisible };
}

export function useComponentVisibility(initialIsVisible = true) {
  const elementRef = useRef<any>(null);

  const [isVisible, setIsVisible] = useState(initialIsVisible);

  const isVisibleRef = useMutable(isVisible);

  useEffect(() => {
    if (
      !elementRef ||
      !elementRef.current ||
      !(elementRef.current instanceof HTMLElement)
    )
      return;

    const element = elementRef.current;

    const handleScroll = throttle(() => {
      function isInViewport() {
        const rect = element.getBoundingClientRect();

        return (
          rect.bottom >= 0 &&
          rect.right >= 0 &&
          rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.left <=
            (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      const inViewport = isInViewport();
      if (inViewport !== isVisibleRef.current) setIsVisible(inViewport);
    }, 300);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [isVisibleRef]);

  return [elementRef, isVisible] as [typeof elementRef, boolean];
}
