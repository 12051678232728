import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export const ScFooter = styled.footer`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (${device.desktop}) {
    width: 100%;
    flex-direction: column;
    margin: 30px 0 80px 0;
  }
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors['text-subtle']};
    margin-right: 35px;
    &:last-child {
      margin: 0;
    }
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors['text-primary']};
    }
    @media (${device.desktop}) {
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    }
  }
`;
