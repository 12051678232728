import React from 'react';

import { useTranslation } from 'react-i18next';

import { env } from 'helpers/env';

import { ScFooter } from 'components/footer/styled';
import { LanguageDropdown } from 'components/languageDropdown';

export const AnonymousFooter = () => {
  return (
    <ScFooter>
      <LanguageDropdown />
    </ScFooter>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <ScFooter>
      {/*<a href={useOldVersionHref(process.env.REACT_APP_TERMS_OF_USE_URL)}>*/}
      {/*  {t('common:footer.termsOfUse')}*/}
      {/*</a>*/}
      <a href={`mailto:${env.REACT_APP_SUPPORT_MAIL}`}>
        {t('common:footer.techSupport')}
      </a>
      {/*<a href={useOldVersionHref(process.env.REACT_APP_CONTACTS_URL)}>*/}
      {/*  {t('common:footer.contact')}*/}
      {/*</a>*/}
      <LanguageDropdown />
    </ScFooter>
  );
};

export default Footer;
