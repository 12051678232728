import { ScBlockShowAnimation, rotate } from 'components/global/Animations';
import { device } from 'components/global/MediaQuery';
import { createStyledObject } from 'components/global/createStyledObject';
import { MTextOverflow } from 'components/global/mixins/all';
import { ScMobileMenuHeight } from 'components/mobileMenu/styled';

import checkbox from 'imgs/checkbox-checked-icon.svg';
import styled, { css } from 'styled-components/macro';

export const ScDropdownCover = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
`;

export interface ScTextAreaProps {
  maxHeight?: number;
}

export const ScTextArea = styled.textarea<ScTextAreaProps>`
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-main']};
  padding-bottom: 13px;
  margin-bottom: 13px;
  font-size: 14px;
  line-height: 22px;
  height: 20px;
  ${({ maxHeight }) =>
    maxHeight != null ? `max-height: ${maxHeight}px;` : null};
`;

export interface ScInputBlockProps {
  disabled?: boolean;
  fullSize?: boolean;
  error?: boolean;
  small?: boolean;
  prefixLength?: number;
  addonsPaddingRight?: number;
}

export const ScInputBlock = styled.div<ScInputBlockProps>`
  ${({ small }) => (small ? 'width: 294px' : 'width: 100%;')};
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &.dirty {
    .input__prefix {
      opacity: 1;
    }
  }
  .input {
    border-radius: ${({ theme }) => theme.radius['radius-fields']};
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? 'transparent' : theme.colors['border-main']};
    color: ${({ theme }) => theme.colors['text-primary']};
    background-color: ${({ theme }) => theme.colors['field-01']};
    ${({ disabled, theme }) =>
      disabled
        ? `cursor: not-allowed;
        background-color: ${theme.colors['field-01-disabled']};`
        : null};
    ${({ theme, error }) =>
      error ? `border-color: ${theme.colors['border-danger']}` : null};
    padding: ${({ theme }) => theme.spacing['field-d-pdn-top']}
      ${({ theme }) => theme.spacing['field-d-pdn-right']};
    padding-right: ${({ addonsPaddingRight, theme }) =>
      addonsPaddingRight
        ? `${addonsPaddingRight}px`
        : theme.spacing['field-d-pdn-right']};
    ${({ prefixLength }) =>
      prefixLength != null && prefixLength !== 0
        ? `padding-left: ${10 * prefixLength}px`
        : null};
    font: ${({ theme }) => theme.typography['paragraph-medium']};
    width: 100%;
    box-sizing: border-box;
    transition-duration: 0.3s;
    text-overflow: ellipsis;
    ::placeholder {
      font: ${({ theme }) => theme.typography['paragraph-medium']};
      color: ${({ theme }) => theme.colors['text-placeholder']};
      padding-right: ${({ theme }) => theme.spacing.primitives['s-20']};
    }
    &__label {
      font: ${({ theme }) => theme.typography['label-small']};
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-08']};
      color: ${({ theme, disabled }) =>
        disabled
          ? theme.colors['text-disabled-subtle']
          : theme.colors['text-primary']};
    }
    &__prefix {
      position: absolute;
      left: 0;
      top: ${({ theme }) => theme.spacing.primitives['s-01']};
      font: ${({ theme }) => theme.typography['label-small']};
      opacity: 0;
      transition: all 0.3s ease;
      pointer-events: none;
    }
    &__loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      background: ${({ theme }) => theme.colors['layer-01']}CC;
      z-index: 2;
      img,
      .currency-icon > svg {
        width: 24px;
        height: 24px;
        margin: auto;
        animation: ${rotate} cubic-bezier(0.18, 0.18, 0.85, 0.85) 3s infinite;
      }
    }
    &__error {
      margin-top: ${({ theme }) => theme.spacing.primitives['s-04']};
      color: ${({ theme }) => theme.colors['text-danger']};
      cursor: default;
      display: inline-flex;
      align-items: center;
      font: ${({ theme }) => theme.typography['paragraph-small']};
      box-sizing: border-box;
      animation: ${ScBlockShowAnimation} 0.3s ease-in;
      user-select: none;
      & > svg {
        margin-right: ${({ theme }) => theme.spacing.primitives['s-08']};
        flex-shrink: 0;
      }
    }
    &:hover {
      background-color: ${({ disabled, theme }) =>
        disabled
          ? theme.colors['field-01-disabled']
          : theme.colors['field-01-hover']};
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors['border-brand']};
      & ~ .input__prefix {
        opacity: 1;
        color: ${({ theme }) => theme.colors['text-brand']};
      }
    }
    &__tooltip {
      color: ${({ theme }) => theme.colors['text-subtle']};
      font: ${({ theme }) => theme.typography['paragraph-small']};
      padding-top: ${({ theme }) => theme.spacing.primitives['s-04']};
      width: 100%;
      span {
        display: block;
      }
      b {
        font-weight: bolder;
      }
    }
    &__addons {
      position: absolute;
      right: ${({ theme }) => theme.spacing['field-d-pdn-right']};
      top: ${({ theme }) => theme.spacing['field-d-pdn-top']};
      font: ${({ theme }) => theme.typography['label-small']};
      box-sizing: border-box;
      ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : null)}
      .select-button {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        &:hover {
          background-color: transparent;
        }
      }
      .input__addons--btn {
        padding: 0;
        justify-content: space-between;
        cursor: pointer;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export const ScInputSimple = styled.input`
  width: 100%;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  border-radius: 4px;
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-16']};
  font-size: 16px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors['text-primary']};
  appearance: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors['border-main']};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors['text-placeholder']};
  }
`;

export interface ScSelectProps {
  disabled?: boolean;
  inline?: boolean;
  error?: boolean;
  simple?: boolean;
}

export const ScSelect = styled.div<ScSelectProps>`
  position: relative;
  color: ${({ theme }) => theme.colors['text-primary']};
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;

  ${({ inline }) => {
    if (inline) {
      return 'width: auto; padding: 0; border: 0;';
    }
    return undefined;
  }};

  .select-button {
    transition: background-color 0.3s ease;
    border: 1px solid
      ${({ theme, simple, error }) =>
        simple
          ? 'transparent'
          : error
            ? theme.colors['border-danger']
            : theme.colors['border-main']};
    background-color: ${({ simple, theme }) =>
      simple ? 'transparent' : theme.colors['field-01']};
    ${({ disabled, theme }) =>
      disabled
        ? `border-color: ${theme.colors['field-01-disabled']}; cursor: not-allowed; pointer-events:none;`
        : null};
    &:hover {
      background-color: ${({ theme, disabled }) =>
        disabled
          ? theme.colors['field-01-disabled']
          : theme.colors['field-01-hover']};
    }
    border-radius: ${({ theme }) => theme.radius['radius-fields']};
    padding: ${({ simple, theme }) =>
      simple ? '0' : theme.spacing['field-d-pdn-top']};
    color: ${({ theme }) => theme.colors['text-primary']};
    font-size: 16px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${({ disabled }) => (disabled ? `cursor: not-allowed; ` : null)};
    .value {
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      height: 24px;
      ${({ inline }) => (inline ? ' margin-right: 5px;' : null)};
      &::-webkit-scrollbar {
        display: none;
      }
    }

    img,
    .currency-icon svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: ${({ theme }) => theme.spacing.primitives['s-10']};
    }
    .currency-icon svg {
      display: block;
    }
    span {
      display: inline-flex;
      ${MTextOverflow};
    }
    .default {
      display: inline-flex;
      align-items: center;
      color: ${({ theme }) => theme.colors['text-disabled-subtle']};
      height: 24px;
    }
    .select-dropdown-img {
      display: inline-flex;
      align-items: center;
      user-select: none;
      flex-shrink: 0;
      transition: all 0.3s ease;
      svg {
        path {
          fill: ${({ disabled, theme }) =>
            disabled
              ? theme.colors['icon-disabled-subtle']
              : theme.colors['icon-primary']};
        }
      }
    }
    &.pressed {
      border-radius: ${({ theme }) => theme.radius['radius-fields']}
        ${({ theme }) => theme.radius['radius-fields']} 0 0;
      border-bottom: 1px solid
        ${({ theme, simple }) =>
          simple ? 'transparent' : theme.colors['border-brand']};
      .select-dropdown-img {
        transform: rotateZ(180deg);
        svg path {
          fill: ${({ theme }) => theme.colors['border-brand']};
        }
      }
    }
  }
`;

export const ScMultiselectSelected = styled.div`
   {
    display: flex;
    flex-direction: row;
    gap: 8px;
    & .selectedOption {
      height: 24px;
      display: flex;
      align-items: center;
      gap: 2px;
      padding: 0 4px 0 8px;
      border-radius: 4px;
      background: rgba(19, 78, 74, 0.1);
      color: ${({ theme }) => theme.colors['text-brand']};
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      svg {
        max-width: 16px;
      }
      &__close {
        height: 16px;
        width: 16px;
        & path {
          stroke: ${({ theme }) => theme.colors['text-brand']};
        }
      }
    }
  }
`;

export enum DropDownPositionType {
  RIGHT,
  LEFT,
}

export const ScCheckboxMultiselect = styled.div`
  min-width: 16px;
  min-height: 16px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.selected {
    background: ${({ theme }) => theme.colors['background-01-inverted']};
    svg {
      opacity: 1;
    }
  }
  svg {
    height: 6px;
    opacity: 0;
  }
`;

export const ScDropDown = createStyledObject({
  Block: styled.div<{ medium?: boolean }>`
    z-index: 14;
    margin: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 100%;
    min-width: 80px;
    max-height: 240px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${({ theme }) => theme.colors['layer-01']};
    border-radius: 0 0 ${({ theme }) => theme.radius['radius-fields']}
      ${({ theme }) => theme.radius['radius-fields']};
    border-right: 1px solid ${({ theme }) => theme.colors['border-main']};
    border-left: 1px solid ${({ theme }) => theme.colors['border-main']};
    border-bottom: 1px solid ${({ theme }) => theme.colors['border-main']};
    ${({ medium }) =>
      medium ? 'width: calc(100% + 200px); right: -100px;' : null};
    @media (${device.desktop}) {
      position: fixed;
      left: 0;
      right: 0;
      top: auto;
      width: auto;
      bottom: ${ScMobileMenuHeight};
      max-height: calc(100% - (${ScMobileMenuHeight}));
      padding-bottom: 0;
      border-radius: 4px 4px 0 0;
    }
  `,

  CloseButton: styled.div`
    display: none;
    z-index: 15;
    box-sizing: border-box;
    padding: 14px 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    order: 0;
    &:hover {
      cursor: pointer;
    }

    @media (${device.tablet}) {
      display: flex;
    }

    svg {
      fill: #caccd0;
      width: 21px;
      height: 9px;
    }
  `,

  SearchContainer: styled.div`
    position: relative;
    z-index: 15;
    box-sizing: border-box;
    margin: 8px 14px;
    display: block;
    align-items: center;
    order: 1;
    & > svg {
      position: absolute;
      right: 8px;
      top: 6px;
    }
    @media (${device.tablet}) {
      padding: 14px;
      order: 3;
    }
  `,

  ListContainer: styled.ul`
    box-sizing: border-box;
    padding: 4px 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    order: 2;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    border-radius: ${({ theme }) => theme.radius['radius-fields']};
  `,

  ListItem: styled.li`
    box-sizing: border-box;
    position: relative;
    padding: 14px;
    width: 100%;
    min-height: fit-content; // safari fix
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors['text-primary']};
    font-size: 16px;
    line-height: 18px;
    user-select: none;
    &:hover {
      background: ${({ theme }) => theme.colors['layer-02']};
      cursor: pointer;
    }

    &.disabled {
      color: ${({ theme }) => theme.colors['text-disabled-subtle']};
      cursor: default;
      &:hover {
        background: none;
      }

      img {
        opacity: 0.3;
      }
    }

    > a {
      width: 100%;
      padding: 14px;
      margin: -14px;
      color: inherit;
      font-size: inherit;
      font-weight: 400;
      position: relative;
      ${MTextOverflow};
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: ${({ theme }) => theme.colors['text-brand']};
        background-color: #e1edfa;
      }
    }

    > img,
    .currency-icon svg {
      display: flex;
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: ${({ theme }) => theme.spacing.primitives['s-10']};
    }

    > span {
      ${MTextOverflow}
    }

    > .tooltip {
      position: absolute;
      bottom: 0;
      left: 15px;
      width: 100%;
      font-size: 12px;
      line-height: 12px;
      color: ${({ theme }) => theme.colors['text-subtle']};
    }

    > .status {
      width: 8px;
      min-width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 12px;
      &--grey {
        background: ${({ theme }) => theme.colors.neutral};
      }
      &--red {
        background: ${({ theme }) => theme.colors.danger};
      }
      &--black {
        background: ${({ theme }) => theme.colors['text-primary']};
      }
      &--pink {
        background: #de004c;
      }
      &--green {
        background: ${({ theme }) => theme.colors.success};
      }
      &--blue {
        background: ${({ theme }) => theme.colors.info};
      }
    }
  `,

  ListItemString: styled.span`
    width: 100%;
    ${MTextOverflow}
  `,
  DisabledListItem: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 4px;
    gap: 2px;
    border-radius: 4px;
    background: #fcdada;
    color: ${({ theme }) => theme.colors['text-danger']};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,
});

export const ScCheckboxError = styled.div`
  color: ${({ theme }) => theme.colors['text-danger']};
  margin-top: ${({ theme }) => theme.spacing.primitives['s-04']};
  position: absolute;
  top: 100%;
  @media (${device.tablet}) {
    text-align: left !important;
    font-size: 11px !important;
  }
`;

const disabledCommentBtnCSS = css`
  background-color: ${({ theme }) => theme.colors['button-disabled-bg']};
  color: ${({ theme }) => theme.colors['text-disabled-strong']};
  pointer-events: none;

  &&&&& .icon {
    background-color: ${({ theme }) => theme.colors['icon-disabled-strong']};
  }
`;

export const ScCommentBtn = styled.button<{
  small?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors['text-brand']};
  text-align: left;
  padding: 0;
  border-radius: ${({ theme }) => theme.radius['radius-button']};
  font: ${({ theme }) => theme.typography['label-medium']};
  ${({ small }) => (small ? 'width: 293px;' : 'width: 100%;')};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors['border-brand']};
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  &:hover {
    background-color: ${({ theme }) => theme.colors['layer-brand-strong']};
  }
  @media (${device.tablet}) {
    width: 100%;
  }
  /*TODO: use &&& because of conflict with .icon styles from ScBlockBody*/
  &&& .icon {
    width: 24px;
    height: 24px;
    background: ${({ theme }) => theme.colors['button-brand']};
    border-radius: 5px;
    padding: 7px;
    margin-right: 13px;
    margin-bottom: 0;
    & > svg {
      width: 100%;
      height: 100%;
      path {
        stroke: ${({ theme }) => theme.colors['icon-primary-inverted']};
        fill: ${({ theme }) => theme.colors['icon-primary-inverted']};
      }
    }
  }

  ${({ disabled }) => (disabled ? disabledCommentBtnCSS : null)}
`;

interface ScCheckboxProps {
  disabled?: boolean;
}

export const ScCheckbox = styled.div<ScCheckboxProps>`
  min-width: 16px;
  width: 16px;
  height: 16px;
  .checkbox {
    display: inline-block;
    border-radius: ${({ theme }) => theme.radius.controls};
    width: 100%;
    height: 100%;
  }
  input {
    display: none !important;
  }
  span {
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? '#9CBDE2' : theme.colors['button-primary']};
    ${({ disabled }) => disabled && 'background-color: transparent'};
    box-sizing: border-box;
  }
  input:checked ~ span {
    background: url(${checkbox}) no-repeat
      ${({ theme }) => theme.colors['button-primary']} 50% 50%;
  }
`;

const disabledCheckboxCSS = css`
  color: ${({ theme }) => theme.colors['text-subtle']};
  pointer-events: none;

  ${ScCheckbox} {
    span {
      border-color: ${({ theme }) => theme.colors['border-disabled']};
    }
    input:checked ~ span {
      background-color: ${({ theme }) => theme.colors['button-disabled-bg']};
    }
  }
`;

export const ScCheckboxLabel = styled.label<{ disabled?: boolean }>`
  position: relative;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors['text-subtle'] : theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media (${device.tablet}) {
    align-items: flex-start;
  }
  ${ScCheckbox} {
    margin-right: 8px;
  }
  & > span {
    font-weight: 400;
    margin: 0 4px;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors['text-primary']};
  }

  ${({ disabled }) => disabled && disabledCheckboxCSS}
`;

export const ScRadioInputGroup = styled.div`
  font-weight: normal;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  input {
    display: none;
    &:checked + div {
      color: ${({ theme }) => theme.colors['text-primary']};
      border-color: ${({ theme }) => theme.colors['border-button']};
      text-decoration: underline 0.5px;
      cursor: initial;
    }
  }
  div {
    color: ${({ theme }) => theme.colors['text-subtle']};
    padding: ${({ theme }) =>
      `${theme.spacing.primitives['s-08']} ${theme.spacing.primitives['s-20']}`};
    font: ${({ theme }) => theme.typography['underlined-large']};
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.radius['radius-pr-subtle']};
    box-sizing: border-box;
    transition: all 0.3s ease;
    &:hover {
      color: ${({ theme }) => theme.colors['text-primary']};
    }
  }
`;

export const ScRelativeBlock = styled.div`
  position: relative;
  width: 100%;
`;

export const ScLimitText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors['text-subtle']};
  margin: 2px 0 0 0;
  &&& > span {
    display: inline-block;
    font-weight: 500;
  }
`;
