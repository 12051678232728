import React, { FC } from 'react';

import { useTheme } from '@involve-software/uikit';

import { GlobalStyles as WalletGlobalStyles } from 'components/GlobalStyles';

import { Theme } from '../themes/contracts';

const GlobalStyles: FC<{ theme: Theme }> = ({ theme }) => {
  const uikitTheme = useTheme();

  return <WalletGlobalStyles theme={{ ...theme, ...uikitTheme }} />;
};

export default GlobalStyles;
