import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export const ForbiddenBlock = styled.div`
  width: 100%;
  max-width: 1268px;
  margin: 0 auto;
`;

export const ForbiddenHeader = styled.header`
  margin: 20px auto 80px;
  padding: 12px 16px;
  width: 100%;
  box-sizing: border-box;

  @media (${device.mobileXXL}) {
    margin-bottom: 50px;
    svg {
      width: 115px;
    }
  }
`;

export const ForbiddenIcon = styled.div`
  max-width: 60%;

  & > svg {
    width: 100%;
    height: 100%;
  }

  @media (${device.mobileXXL}) {
    width: 155px;
    height: 155px;
  }
`;

export const ForbiddenBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 835px;
  margin: 0 auto;
  padding: 0 16px 32px;
`;

export const ForbiddenTitle = styled.p`
  color: ${({ theme }) => theme.colors['text-primary']};
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin: 56px 0 0;

  @media (${device.mobileXXL}) {
    margin: 48px 0 0;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const ForbiddenSubtitle = styled.p`
  color: #3b4754;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 24px 0 0;

  @media (${device.mobileXXL}) {
    margin: 12px 0 0;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ForbiddenInfo = styled.div`
  color: #3b4754;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};

  & > p {
    margin: 0 0 8px;

    span {
      font-weight: 500;
    }
  }

  @media (${device.mobileXXL}) {
    margin-top: 16px;

    & > p {
      margin: 0 0 4px;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
