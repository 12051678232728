import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

export interface DelayedRenderProps {
  delay: number;
}

export const DelayedRender: FC<PropsWithChildren<DelayedRenderProps>> = ({
  delay,
  children,
}) => {
  const [show, setShow] = useState(delay <= 0);

  useEffect(() => {
    if (delay > 0) {
      const timeout = setTimeout(() => setShow(true), delay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [delay]);

  return show ? <>{children}</> : null;
};
