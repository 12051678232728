export function hideParamsFromUrl(
  url: string,
  marker: string,
  masks: string[]
) {
  let urlUpdate = url;

  for (let i = 0; i < masks.length; i++) {
    const strRegExp =
      masks[i].replace(/\//g, '\\/').replace(marker, '([0-9A-Za-z]*)') +
      '(\\/*)?(\\?.*)?$';

    const regExp = new RegExp(strRegExp);
    const matchResult = url?.match(regExp);

    if (matchResult && matchResult[1]) {
      urlUpdate = url.replace(matchResult[1], marker);
      break;
    }
  }

  return urlUpdate;
}
