import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { ThemeProvider } from 'styled-components';

import { Theme } from 'themes/contracts';

const ScThemeProvider: React.FC<{ theme: Theme } & React.PropsWithChildren> = ({
  theme,
  children,
}) => {
  const uikitTheme = useTheme();
  return (
    <ThemeProvider theme={{ ...uikitTheme, theme }}>{children}</ThemeProvider>
  );
};

export default ScThemeProvider;
