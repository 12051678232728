export enum ShopStatus {
  NEW = 1,
  ACTIVE = 2,
  BLOCKED = 3,
  DELETED = 4,
  VERIFIED = 5,
}

export enum FormStatus {
  INITIAL = 1,
  SAVED,
  ERROR,
  NOT_CHANGED,
}

export enum ShopPaymentStatus {
  WAITING = 3,
  SUCCESS = 5,
  REJECTED = 6,
  REFUNDED = 11,
}

export enum ShopCryptoPaymentStatus {
  WAITING = 2,
  SUCCESS,
  REJECTED,
}

export enum ShopDepositStatus {
  WAITING = 1,
  SUCCESS,
  REJECTED,
  REFUNDED,
  HELD,
  CAPTURED,
  EXPIRED,
  REFUNDING,
}

export enum ShopCryptoDepositStatus {
  WAITING = 1,
  SUCCESS,
  REJECTED,
  CAPTURED,
  RISKY,
  REFUNDING,
  REFUNDED,
  EXPIRED,
}

export enum ShopStatementType {
  INVOICE = 1,
  WITHDRAW,
  SYSTEM,
}

export enum TwoFactorType {
  NONE,
  GCODE,
}

export enum TwoFactorAction {
  AUTH = 2,
  SHOP_SETTINGS = 20,
  SHOP_INPUT_PAY_METHODS = 22,
  PAYMENTS = 38,
}

export enum MediaType {
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum MediaQuery {
  MOBILE = '(max-width: 768px)',
  TABLET = '(min-width: 768px) and (max-width: 928px)',
  DESKTOP = '(min-width: 928px)',
}

export enum Permission {
  BALANCE = 'balances',
  HISTORY = 'reports',
  SHOP_SETTINGS = 'tech_settings',
  ANALYTICS = 'analytics',
  HISTORY_DOWNLOAD = 'download',
}

export enum RecaptchaVersion {
  V2 = 2,
  V3 = 3,
}

export enum RecaptchaV3Action {
  LOGIN = 'login',
}
