import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import { getMirroringConfigWithDefault } from 'helpers/mirroring';

import { DelayedRender } from 'components/internal/delayedRender';
import { Portal } from 'components/portal';

import { ScLoader, ScLoaderProps, ScLoadingText } from './styled';

interface LoaderProps extends ScLoaderProps {
  textMode?: boolean;
  on?: boolean;
}

export function Loader({ textMode, on, inline, ...rest }: LoaderProps) {
  const { t } = useTranslation('common');
  const { images } = useTheme();
  const loader = (
    <ScLoader inline={inline} {...rest}>
      {textMode ? (
        <ScLoadingText>{t('common:loading')}...</ScLoadingText>
      ) : (
        <img
          src={getMirroringConfigWithDefault('loader', images.loader.path)}
          alt=""
        />
      )}
    </ScLoader>
  );

  if (typeof on === 'undefined' || on) {
    return inline ? loader : <Portal>{loader}</Portal>;
  }

  return null;
}

interface DelayedLoaderProps extends LoaderProps {
  delay?: number;
}

export function DelayedLoader({
  delay = 300,
  on,
  ...rest
}: DelayedLoaderProps) {
  return typeof on === 'undefined' || on ? (
    <DelayedRender delay={delay}>
      <Loader {...rest} />
    </DelayedRender>
  ) : null;
}

export default Loader;
