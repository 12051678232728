import hash from 'object-hash';

import { isPromise } from 'helpers/common';

export interface CacheItem<D> {
  timestamp: number;
  data: D;
}

export interface CacheProvider<D> {
  set(key: string, data: CacheItem<D>): void;
  get(key: string): CacheItem<D> | void;
  remove(key: string): void;
  clear(): void;
}

export interface MemoizeConfig<Data> {
  maxAge: number;
  cacheProvider?: CacheProvider<Data>;
}

export function memoize<This, Args extends any[], Return>(
  fn: (this: This, ...args: Args) => Return,
  config: MemoizeConfig<Return>
): ((this: This, ...args: Args) => Return) & {
  cacheProvider: CacheProvider<Return>;
} {
  const cacheProvider = config.cacheProvider || memoryCacheProviderFactory();

  function memoized(this: This, ...args: Args) {
    const key = hash(args);

    function setCache(data: Return) {
      cacheProvider.set(key, {
        data,
        timestamp: Date.now(),
      });
    }

    const cache = cacheProvider.get(key);

    if (cache) {
      const { data, timestamp } = cache;

      if (Date.now() - timestamp < config.maxAge) return data;

      cacheProvider.remove(key);
    }

    const data = fn.apply(this, args);

    if (isPromise(data)) {
      return data.then((data) => {
        setCache(data);

        return data;
      }) as any as Return;
    }

    setCache(data);

    return data;
  }

  memoized.cacheProvider = cacheProvider;

  return memoized;
}

export function memoryCacheProviderFactory<Data = any>(): CacheProvider<Data> {
  let cache: {
    [key: string]: CacheItem<Data>;
  } = {};

  return {
    set(key, data) {
      cache[key] = data;
    },

    get(key) {
      return cache[key];
    },

    remove(key) {
      delete cache[key];
    },

    clear() {
      cache = {};
    },
  };
}

export function storageCacheProviderFactory<T = any>(
  storage: Storage,
  cacheKey: string
): CacheProvider<T> {
  const keys = new Set<string>();

  function buildKey(key: string) {
    return `memoize:${cacheKey}:${key}`;
  }

  return {
    get(key) {
      const item = storage.getItem(buildKey(key));

      if (item == null) {
        return;
      }

      return JSON.parse(item);
    },
    set(key, data) {
      const k = buildKey(key);

      storage.setItem(k, JSON.stringify(data));

      keys.add(k);
    },
    remove(key) {
      const k = buildKey(key);

      storage.removeItem(k);

      keys.delete(k);
    },

    clear() {
      keys.forEach((key) => storage.removeItem(key));

      keys.clear();
    },
  };
}
