import produce from 'immer';
import { GetState, StateCreator, StoreApi } from 'zustand';

export function immer<T>(
  config: (
    set: (creator: (state: T) => void) => void,
    get: GetState<T>,
    api: StoreApi<T>
  ) => T
) {
  const creator: StateCreator<T> = (set, get, api) =>
    config((fn) => set(produce(fn) as any), get, api);

  return creator;
}
