import {
  DependencyList,
  EffectCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { isObject, shallowEqual } from 'helpers/common';

export function useMutable<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref;
}

export function useLayoutMutable<T>(value: T) {
  const ref = useRef(value);

  useLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
}

export function useMount() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}

export function useShallowDepsCompare(deps?: DependencyList) {
  const depsRef = useRef(deps);

  if (depsRef.current === deps) return depsRef.current;

  function reset() {
    depsRef.current = deps;
  }

  if (
    depsRef &&
    depsRef.current &&
    deps &&
    depsRef.current.length === deps.length
  ) {
    const differ = deps.some((newValue, index) => {
      if (depsRef.current == null) return true;

      const oldValue = depsRef.current[index];

      if (typeof oldValue !== typeof newValue) {
        return true;
      } else if (Array.isArray(newValue) || isObject(newValue)) {
        if (!shallowEqual(oldValue, newValue)) {
          return true;
        }
      } else if (oldValue !== newValue) {
        return true;
      }

      return false;
    });

    if (differ) reset();
  } else {
    reset();
  }

  return depsRef.current;
}

export function useShallowMemo<T>(
  factory: () => T,
  deps: DependencyList | undefined
): T {
  return useMemo(factory, [factory, deps]); // TODO: (?)
}

export function useShallowCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): T {
  return useShallowMemo(() => callback, deps);
}

export function useOnUpdateEffect(
  effect: EffectCallback,
  deps?: DependencyList
) {
  const mountRender = useRef(true);

  function effectHandler() {
    if (mountRender.current) {
      mountRender.current = false;
      return;
    }

    return effect();
  }

  // TODO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHandler, deps);
}
