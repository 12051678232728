import { keyframes } from 'styled-components/macro';

export const smoothRotate = keyframes`
  0%{
    transform: rotate(0deg) ;
  }
  100%{
    transform: rotate(180deg) ;
  }
`;
export const jump = keyframes`
  0%{  transform: translateY(10px);}
  50%{  transform: translateY(-60px);}
  0%{ transform: translateY(10px);}
`;
export const moveLeft = keyframes`
  0%{
    transform: translateX(0);
    left: -60px;
  }
  100%{
   transform: translateX(100%);
   left: calc(100% + 60px );
  }
`;

export const slowShow = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

export const rotate = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

export const ScBlinkDotAnimation = keyframes`
 50% {
  color: transparent;
  } 
`;

export const AnimationHideKeyframes = keyframes`
  0%{
    transform: translateX(100%)
  } 
  4%{
    transform: translateX(0);
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0
  }
`;

export const AnimationTopHideKeyframes = keyframes`
  0%{
    transform: translateY(-100%)
  } 
  4%{
    transform: translateY(0);
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0
  }
`;
export const showHideAnimation = keyframes`
  0%{opacity: 0}
  50%{opacity: 1}
  100%{opacity: 0}
`;
export const slowShowAnimation = keyframes`
  0%{opacity:0}
  100%{opacity: 1}
`;
export const slideUp = keyframes`
  0%{transform: translateY(100%)}
  100%{transform: translateY(0)}
`;

export const ScBlockShowAnimation = keyframes`
0%{
  clip-path: circle(1% at 0 0);
}
100%{
 clip-path: circle(100% at 50% 50%);
 }
`;

export const sideMenuAnimation = keyframes`
0%{
  clip-path: circle(10px at 0 0);
}
100%{
 clip-path: circle(100% at 50% 50% );
  }
`;
