import { useEffect, useState } from 'react';

import { useShallowCallback, useShallowMemo } from 'hooks/common';

import { MediaQuery, MediaType } from 'helpers/enums';

export function useMedia<T>(queries: string[], values: T[], defaultValue: T) {
  const mediaQueryLists = useShallowMemo(
    () => queries.map((q) => window.matchMedia(q)),
    [queries]
  );

  const getValue = useShallowCallback(() => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  }, [mediaQueryLists, values, defaultValue]);

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [getValue, mediaQueryLists]);

  return value;
}

const queries = [MediaQuery.DESKTOP, MediaQuery.TABLET, MediaQuery.MOBILE];
const types = [MediaType.DESKTOP, MediaType.TABLET, MediaType.MOBILE];
export function useCurrentMediaType() {
  return useMedia(queries, types, undefined);
}
