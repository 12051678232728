import { device } from 'components/global/MediaQuery';

import arrowIconGrey from 'imgs/arrow_down-new--grey.svg';
import styled from 'styled-components/macro';

interface ScAvatarIconProps {
  avatarImg?: string;
}

interface ScHeaderBlockPros {
  anonymous?: boolean;
}

export const ScHeaderBlock = styled.div<ScHeaderBlockPros>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid
    ${({ anonymous, theme }) =>
      anonymous ? 'transparent' : theme.colors['border-subtle-01']};
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
  background-color: ${({ theme, anonymous }) =>
    anonymous ? 'transparent' : theme.colors['layer-01']};
  @media (${device.tablet}) {
    padding-left: 0;
  }
  header {
    margin: 0 auto;
    padding: ${({ theme }) => theme.spacing.primitives['s-12']}
      ${({ theme }) => theme.spacing.primitives['s-16']};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: ${({ anonymous }) => (anonymous ? '1268px' : '928px')};
    box-sizing: border-box;
    .logo {
      display: inline-flex;
      align-items: center;
      height: 48px;
      flex-shrink: 0;
      & > img {
        height: 100%;
      }
      @media (${device.tablet}) {
        overflow: hidden;
      }
    }
    &.anonymous-header {
      display: flex;
      position: relative;
      z-index: 2;
      background-color: transparent;
      border: none;
      padding-left: 0;
      padding-right: 0;
      .logo {
        height: 48px;
        @media (${device.tablet}) {
          overflow: hidden;
          height: 35px;
        }
      }
    }
  }
`;

export const ScAvatarIcon = styled.div<ScAvatarIconProps>`
  background: url(${({ avatarImg, theme }) =>
      avatarImg ? avatarImg : theme.images.avatarDefaultIcon.path})
    no-repeat center / contain;
  width: 36px;
  height: 36px;
  margin: 0 0 0 16px;
`;

interface ScAccountNumberProps {
  open: boolean;
}
export const ScAccountNumber = styled.div<ScAccountNumberProps>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors['text-primary']};
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    margin-left: 7px;
    width: 10px;
    height: 6px;
    background: url(${arrowIconGrey}) no-repeat 50% 50%;
    transition-duration: 0.3s;
    ${({ open }) => open && `transform: rotate(180deg)`}
  }
`;
export const ScAccountInfo = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  position: relative;
  padding-top: 2px;
  @media (${device.desktop}) {
    padding-top: 0;
    margin-right: ${({ theme }) => theme.spacing.primitives['s-10']};
  }

  a,
  button {
    color: ${({ theme }) => theme.colors['text-subtle']};
    font-weight: 400;

    &.link-old-version {
      text-transform: uppercase;
      font-size: 12px;
      padding: 0 ${({ theme }) => theme.spacing.primitives['s-28']};

      &:hover {
        text-decoration: none;
      }

      @media (${device.desktop}) {
        display: none;
      }
    }
  }
`;

export const ScAccountDropDown = styled.ul`
  position: absolute;
  z-index: 10;
  padding: 0;
  margin: 0;
  top: 100%;
  list-style: none;
  right: 0;
  background: ${({ theme }) => theme.colors['layer-01']};
  border: ${({ theme }) => `1px solid ${theme.colors['border-main']}`};
  border-radius: ${({ theme }) => theme.radius['radius-pr-strong']};
  box-sizing: border-box;
  overflow: hidden;

  li {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.primitives['s-08']}
      ${({ theme }) => theme.spacing.primitives['s-16']};
    font-size: 14px;
    color: ${({ theme }) => theme.colors['text-primary']};
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    cursor: pointer;
    transition-duration: 0.3s;
    &:last-child {
      border-top: 1px solid #f2f2f2;
    }
    & > a {
      font-size: inherit;
      color: inherit;
      display: flex;
      align-items: center;
      text-decoration: none;
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
`;
