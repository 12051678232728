import React, { FC, PropsWithChildren } from 'react';

import i18n from 'i18next';
import {
  Matcher,
  NotFoundError,
  map,
  redirect,
  withContext,
  withView,
} from 'navi';
import { I18nextProviderProps, TFunction, Translation } from 'react-i18next';
import { useViewElement } from 'react-navi';

import { Permission } from 'helpers/enums';
import { TranslationMapping } from 'helpers/error';
import { hasLoadedNamespace, loadNamespaces } from 'helpers/i18n';
import { errorTranslationFactory, translationFactory } from 'helpers/trans';

import { ErrorBlock } from 'components/block';

import i18nInitialization, { i18nInstance } from 'libs/i18n';
import { AuthenticatedRouteContext, GlobalRouteContext } from 'types/routes';

export const ResolveChildView = ({
  children,
}: {
  children: (view: React.ReactNode) => JSX.Element;
}) => {
  const el = useViewElement();

  if (el == null) {
    return null;
  }

  return children(el);
};

export function withResolvedView(Component: FC<PropsWithChildren<any>>) {
  return withView(
    <ResolveChildView>
      {(view) => <Component>{view}</Component>}
    </ResolveChildView>
  );
}

export function loginRedirect(next: string, query?: string) {
  let search = `next=${encodeURIComponent(next)}`;

  if (query) {
    search += '&' + query;
  }

  return redirect(`/login/?${search}`, { exact: false });
}

export function withAuthentication<Context extends GlobalRouteContext>(
  matcher: Matcher<any>
) {
  return map<Context>((request, context) =>
    context.userAuthenticated
      ? matcher
      : loginRedirect(
          request.path + request.search,
          request.query.lng != null ? `lng=${request.query.lng}` : undefined
        )
  );
}
export function withSectionView() {
  return withResolvedView(({ children }) => <section>{children}</section>);
}

export function withAllPermissions<Context extends AuthenticatedRouteContext>(
  perms: Permission[],
  matcher: Matcher<any>
) {
  return map<Context>((request, context) => {
    if (perms.every((perm) => context.account.permissions[perm])) {
      return matcher;
    } else {
      throw new NotFoundError(request.pathname);
    }
  });
}

export function withSomePermission<Context extends AuthenticatedRouteContext>(
  perms: Permission[],
  matcher: Matcher<any>
) {
  return map<Context>((request, context) => {
    if (perms.some((perm) => context.account.permissions[perm])) {
      return matcher;
    } else {
      throw new NotFoundError(request.pathname);
    }
  });
}

export interface RouteTranslationContext {
  t: TFunction;
  i18n: I18nextProviderProps['i18n'];
}
export function withTranslation<ParentContext extends object = any>(
  ns: string | string[]
) {
  const namespaces = typeof ns === 'string' ? [ns] : ns;

  return withContext<ParentContext, RouteTranslationContext>(
    async (_request, parentContext) => {
      const ready =
        i18nInstance &&
        (i18nInstance.isInitialized ||
          (i18nInstance as any).initializedStoreOnce) &&
        namespaces.every((n) => hasLoadedNamespace(n));

      if (!ready) {
        await i18nInitialization;
        await new Promise((resolve) => loadNamespaces(ns, resolve));
      }

      return {
        ...parentContext,
        t: i18n.getFixedT(null, namespaces[0]),
        i18n: i18nInstance,
      };
    }
  );
}

export const titleGetter = translationFactory;

export interface RouteErrorViewOptions {
  block?: (error: string) => React.ReactElement | null;
  def?: string | string[];
}

export function routeErrorView(
  errorCode: number,
  mapping: TranslationMapping = {},
  { block, def }: RouteErrorViewOptions = {}
) {
  const translateError = errorTranslationFactory(
    errorCode,
    mapping,
    def || 'common:serverError.download'
  );

  return {
    title: translateError,
    view: (
      <Translation>
        {(t) => {
          const error = translateError(t);

          if (block) {
            return block(error);
          }

          return <ErrorBlock withReload>{error}</ErrorBlock>;
        }}
      </Translation>
    ),
  };
}
