import React, { FC, PropsWithChildren, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { NotFoundBoundary, useCurrentRoute, useLoadingRoute } from 'react-navi';

import { NotFoundLayout } from 'components/notfound';

import { GlobalTooltip } from 'modules/globalTooltip';

import 'nprogress/nprogress.css';
// @ts-ignore
import nprogress from 'nprogress/nprogress.js';

const useTitleSetter = () => {
  const { title } = useCurrentRoute();

  const { t } = useTranslation();

  useEffect(() => {
    switch (typeof title) {
      case 'function': {
        document.title = title(t);
        break;
      }
      case 'string': {
        document.title = title;
        break;
      }
      default: {
        document.title = 'Wallet';
      }
    }
  }, [title, t]);
};

const useLoadingBar = () => {
  const routeLoading = !!useLoadingRoute();

  useEffect(() => {
    nprogress.configure({ parent: 'body', easing: 'ease-in-out', speed: 400 });

    return () => nprogress.remove();
  }, []);

  useEffect(() => {
    if (routeLoading) {
      nprogress.start();

      const interval = setInterval(() => {
        nprogress.inc(0.3 * Math.cos(nprogress.status * (Math.PI / 2)));
      }, 300);

      return () => clearInterval(interval);
    } else {
      nprogress.done();
    }
  }, [routeLoading]);
};

export const Layout: FC<PropsWithChildren<any>> = ({ children }) => {
  useTitleSetter();

  useLoadingBar();

  return (
    <>
      <GlobalTooltip />

      <NotFoundBoundary render={NotFoundLayout}>{children}</NotFoundBoundary>
    </>
  );
};
