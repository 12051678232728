import { Link } from 'react-navi';

import { slowShowAnimation } from 'components/global/Animations';
import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export interface ScButtonProps {
  primary?: true;
  borderLess?: true;
  customWidth?: string;
  small?: boolean;
  link?: true;
  minimalism?: boolean;
  wide?: boolean;
  danger?: boolean;
}
export const ScButton = styled.button<ScButtonProps>`
  white-space: nowrap;
  width: ${({ wide }) => (wide ? '100%' : 'auto')};
  color: ${({ primary, danger, theme }) =>
    danger && !primary
      ? theme.colors['text-danger']
      : primary
        ? theme.colors['text-primary-inverted']
        : theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['label-medium']};
  border: ${({ borderLess, link, danger, primary, theme }) =>
    borderLess || link
      ? '0'
      : danger
        ? `1px solid ${theme.colors['border-danger']};`
        : primary
          ? 'transparent'
          : `1px solid ${theme.colors['border-button']};`};
  border-radius: ${({ theme }) => theme.radius['radius-button']};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ link }) => (link ? '4px' : '10px')};
  height: ${({ small }) => (small ? '40px' : '48px')};
  min-height: ${({ small }) => (small ? '40px' : '48px')};
  padding: ${({ link, small, theme }) =>
    link
      ? '0'
      : small
        ? `0 ${theme.spacing.primitives['s-12']}`
        : `0 ${theme.spacing.primitives['s-24']}`};
  min-width: ${({ small }) => (small ? '130px' : 'initial')};
  outline: none;
  cursor: pointer;
  margin: 0;
  letter-spacing: 0.014em;
  background-color: ${({ primary, danger, theme }) => {
    if (danger && primary) {
      return theme.colors['button-danger'];
    } else if (primary) {
      return theme.colors['button-primary'];
    } else {
      return 'transparent';
    }
  }};

  ${({ minimalism, theme }) =>
    minimalism &&
    `border: none; padding: 0 ${theme.spacing.primitives['s-16']};`}

  &:active {
    background-color: ${({ primary, danger, theme }) => {
      if (danger && primary) {
        return theme.colors['button-danger-pressed'];
      } else if (danger) {
        return theme.colors['button-secondary-pressed'];
      } else if (primary) {
        return theme.colors['button-primary-pressed'];
      } else {
        return 'transparent';
      }
    }};
  }
  &:hover {
    background-color: ${({ primary, danger, theme }) => {
      if (danger && primary) {
        return theme.colors['button-danger-hover'];
      } else if (danger) {
        return theme.colors['button-secondary-hover'];
      } else if (primary) {
        return theme.colors['button-primary-hover'];
      } else {
        return theme.colors['button-secondary-hover'];
      }
    }};
    border-color: ${({ theme, danger }) =>
      danger
        ? theme.colors['button-danger-hover']
        : theme.colors['button-primary-hover']};
    text-decoration: none;
  }
  &:disabled {
    color: ${({ theme, danger, primary }) => {
      if (danger && primary) {
        return theme.colors['text-disabled-strong'];
      }
      if (primary) {
        return theme.colors['text-disabled-strong'];
      } else {
        return theme.colors['text-disabled-subtle'];
      }
    }};
    background-color: ${({ primary, danger, theme }) => {
      if (danger || primary) {
        return theme.colors['button-disabled-bg'];
      } else {
        return 'transparent';
      }
    }};
    border-color: ${({ theme, danger, primary }) =>
      danger || primary ? theme.colors['button-disabled-bg'] : 'transparent'};
    cursor: not-allowed;
  }

  .button-icon {
    flex-shrink: 0;
  }
  @media (${device.tablet}) {
    ${({ minimalism }) => minimalism && `width: auto !important;`}
    padding: ${({ theme }) => theme.spacing.primitives['s-12']};
  }
`;

export const ScLink = styled(Link)`
  display: inline-flex;
  flex: none;
  align-items: center;
  outline: none;
  &:hover {
    text-decoration: none;
  }
` as any as typeof Link;

export const ScButtonLink = styled.button<{ small?: true }>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors['text-brand']};
  padding: 0;
  ${({ small, theme }) =>
    small
      ? `font: ${theme.typography['label-x-small']}`
      : `font: ${theme.typography['label-small']}`};
  border: 0;
  background: none;
  outline: none;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors['text-brand']};
    text-decoration: underline;
  }
`;

interface ScLinkWithIconProps {
  icon?: any;
  iconLeft?: true;
}
export const ScLinkWithIcon = styled(ScButtonLink)<ScLinkWithIconProps>`
  background: url(${({ icon }) => icon}) no-repeat;
  font-size: 14px;
  padding: ${({ iconLeft }) =>
    iconLeft ? '5px 0 5px 30px' : '5px 30px 5px 0'};
  margin: 0;
  background-position: ${({ iconLeft }) => (iconLeft ? '0%' : '100%')};
  &:hover {
    background-color: transparent;
  }
  &:active {
    background: url(${({ icon }) => icon}) no-repeat;
    background-position: ${({ iconLeft }) => (iconLeft ? '0%' : '99%')};
  }
`;

export const ScRadioBtnLabel = styled.label`
  font-size: 14px;
  &:hover input ~ div {
    background: ${({ theme }) => theme.colors['layer-02']};
    border-color: ${({ theme }) => theme.colors['button-primary']};
  }
  input {
    visibility: hidden;
    position: absolute;
    &:checked ~ div {
      border-color: ${({ theme }) => theme.colors['button-primary']};
      span {
        opacity: 1;
        background: ${({ theme }) => theme.colors['button-primary']};
      }
    }
  }
`;
export const ScRadioBtn = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors['button-primary']};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  box-sizing: border-box;
  span {
    transition: 0.3s ease;
    position: absolute;
    background: ${({ theme }) => theme.colors['button-primary']};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    opacity: 0;
    box-sizing: border-box;
  }
`;
export const ScTriggerLabel = styled.label`
  margin-left: 10px;
  div {
    width: 44px;
    height: 24px;
    background: ${({ theme }) => theme.colors['layer-02']};
    border: 1px solid ${({ theme }) => theme.colors['button-primary']};
    display: inline-block;
    position: relative;
    border-radius: ${({ theme }) => theme.radius['radius-pr-subtle']};
    cursor: pointer;
    transition: 0.3s ease;
  }
  input {
    visibility: hidden;
    position: absolute;
    &:checked ~ div {
      background: ${({ theme }) => theme.colors['button-brand']};
      border-color: ${({ theme }) => theme.colors['button-brand']};
    }
    &:checked ~ div span {
      background-color: ${({ theme }) => theme.colors['layer-01']};
      border-color: ${({ theme }) => theme.colors['layer-01']};
      left: calc(
        100% - 16px - ${({ theme }) => theme.spacing.primitives['s-04']}
      );
    }
  }
  span {
    width: 16px;
    height: 16px;
    border-radius: ${({ theme }) => theme.radius.controls};
    border: 1px solid ${({ theme }) => theme.colors['button-primary']};
    background: ${({ theme }) => theme.colors['layer-01']};
    position: absolute;
    left: ${({ theme }) => theme.spacing.primitives['s-04']};
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease;
  }
`;

export const ScButtonToTop = styled.button`
  position: fixed;
  z-index: 98;
  right: 30px;
  bottom: 98px;
  background: ${({ theme }) => theme.colors['button-brand']};
  color: #fff;
  border-radius: 50%;
  border: 0;
  width: 45px;
  height: 45px;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
  text-transform: uppercase;
  padding-bottom: 9px;
  @media (${device.tablet}) {
    right: auto;
    left: 25px;
    bottom: 85px;
  }
  img {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-02']};
  }
  animation: ${slowShowAnimation} 1s;
`;

export interface ScSimpleLinkProps {
  underline?: boolean;
}

export const ScSimpleLink = styled.button<ScSimpleLinkProps>`
  background-color: transparent;
  text-decoration: none;
  font-size: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 0;
  margin: 0;
  padding: 0;
  display: inline;
  ${({ underline }) =>
    underline ? '&:hover { text-decoration: underline; }' : null}
`;

interface ScIconButtonProps {
  primary?: boolean;
  loading?: boolean;
}

export const ScIconButton = styled.button<ScIconButtonProps>`
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.primitives['s-08']};
  outline: none;
  cursor: pointer;
  margin: 0;
  background: ${({ primary, theme }) =>
    primary ? theme.colors['button-primary'] : 'transparent'};
`;

export const ScDelButton = styled.button`
  padding: ${({ theme }) => theme.spacing.primitives['s-08']};
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors['icon-danger']};
    }
  }
`;

export const ScLinkBack = styled(Link)`
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 4px;
  color: ${({ theme }) => theme.colors['icon-brand']};
  text-decoration: underline;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ theme }) => theme.colors['icon-brand']};
    }
  }
`;
