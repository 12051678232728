import { useEffect } from 'react';

const faviconElements = [
  document.querySelector('link[rel="shortcut icon"]'),
  document.querySelector('link[rel="apple-touch-icon"]'),
  document.querySelector('link[rel="icon"][sizes="16x16"]'),
  document.querySelector('link[rel="icon"][sizes="32x32"]'),
];

export const useReplaceFavicon = (faviconPath?: string) => {
  useEffect(() => {
    if (faviconPath == null) return;

    faviconElements.forEach((element) => {
      element?.setAttribute('href', faviconPath);
    });
  }, [faviconPath]);
};
