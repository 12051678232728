import React, { FC, Suspense, useContext, useEffect, useState } from 'react';

import { useTheme } from '@involve-software/uikit';
import * as Sentry from '@sentry/react';

import { Matcher } from 'navi';
import { Router, View } from 'react-navi';

import { Layout } from 'components/Layout';

import { AuthServiceContext } from 'modules/authService/context';

import { deskieWidget } from '3rdParty/deskieWidget';

function setAuthenticationBreadcrumb(isAuthenticated: boolean) {
  if (isAuthenticated) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged in',
      level: 'info',
    });
  } else {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged out',
      level: 'info',
    });
  }
}

export const Routes: FC<{ routes: Matcher<any> }> = ({ routes }) => {
  const authService = useContext(AuthServiceContext);

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    authService.isAuthenticated
  );

  useEffect(() => authService.subscribe(setIsUserAuthenticated), [authService]);
  useEffect(
    () => authService.subscribe(setAuthenticationBreadcrumb),
    [authService]
  );

  useEffect(() => deskieWidget.add(), []);

  const theme = useTheme();

  return (
    <Router
      routes={routes}
      context={{ userAuthenticated: isUserAuthenticated, authService, theme }}
    >
      <Layout>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Layout>
    </Router>
  );
};
