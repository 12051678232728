import React, { FC, useCallback, useMemo } from 'react';

import * as Sentry from '@sentry/react';

import { Navigation, compose, withContext, withView } from 'navi';
import { useNavigation } from 'react-navi';

import { account as fetchAccount } from 'api/auth';

import { valueChangeFactory } from 'helpers/common';
import { memoize } from 'helpers/memoization';
import { ResolveChildView } from 'helpers/routes';

import { InternalErrorBlock } from 'components/internalError';

import { AuthenticatedRouteContext, GlobalRouteContext } from 'types/routes';

export type AccountContextData = AuthenticatedRouteContext['account'];

export interface AccountContextActions {
  refreshAccount: Navigation['refresh'];
}

const cachedAccountFetch = memoize(fetchAccount, { maxAge: 6e4 });

export const AccountContext = React.createContext<
  AccountContextData & AccountContextActions
>(null as any);

const AccountContextProvider: FC<{
  context: AccountContextData;
  children: React.ReactNode;
}> = ({ context, children }) => {
  const navigation = useNavigation();

  const refreshAccount = useCallback(() => {
    cachedAccountFetch.cacheProvider.clear();

    return navigation.refresh();
  }, [navigation]);

  const contextValue = useMemo(
    () => ({ ...context, refreshAccount }),
    [context, refreshAccount]
  );

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

const valueChanged = valueChangeFactory();

export function withAccountContext() {
  return compose(
    withContext<
      GlobalRouteContext,
      GlobalRouteContext & {
        account: AuthenticatedRouteContext['account'] | undefined;
      }
    >(async (request, parent) => {
      try {
        // reset account cache on login key change
        if (valueChanged(parent.authService.loginKey)) {
          cachedAccountFetch.cacheProvider.clear();
        }

        const { data: account } = await cachedAccountFetch();

        Sentry.addBreadcrumb({
          category: 'auth',
          message: `Logged User Id: ${account.id}`,
          level: 'info',
        });

        Sentry.setUser({ id: account.id.toString() });

        return { ...parent, account };
      } catch (e) {
        return { ...parent, account: undefined };
      }
    }),
    withView((_, { account }) => {
      if (account == null) {
        return (
          <section>
            <InternalErrorBlock />
          </section>
        );
      }

      return (
        <ResolveChildView>
          {(view) => (
            <AccountContextProvider context={account}>
              {view}
            </AccountContextProvider>
          )}
        </ResolveChildView>
      );
    })
  );
}
