export function createStyledObject(obj: any[any]) {
  return Object.entries(obj).reduce((acc: any[any], [key, value]) => {
    if (typeof value == 'function') {
      acc[key] = value(obj);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
}
