import React, { useState } from 'react';

import { Dropdown, useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import { useLanguageChange } from 'hooks/translation';

import Loader from 'components/loader';

import { languagesInfo } from 'libs/i18n';

import * as Styled from './styled';

export const LanguageDropdown = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const [changeLanguage, pending] = useLanguageChange();
  const [open, setOpen] = useState(false);

  const { images } = useTheme();

  return (
    <>
      <Loader on={pending} />

      <Dropdown
        open={open}
        onOpenChange={setOpen}
        trigger={
          <Styled.LanguageDropdownHeader $isOpen={open}>
            <Styled.Option>
              <images.languageIcon.component />
              {languagesInfo[language].name}
            </Styled.Option>
            <images.arrowSelect.component />
          </Styled.LanguageDropdownHeader>
        }
        size="small"
        side="bottom"
        align="center"
        sideOffset={2}
      >
        {Object.keys(languagesInfo).map((key) => (
          <Dropdown.Item
            key={key}
            onClick={() => {
              changeLanguage(languagesInfo[key].code);
              setOpen(false);
            }}
            $active={language === languagesInfo[key].code}
          >
            <Styled.Option>{languagesInfo[key].name}</Styled.Option>
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  );
};
