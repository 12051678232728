import { fallbackLng } from 'libs/i18n';

export const fetchLanguageByIP = async () => {
  try {
    const response = await fetch('/geo');

    if (response.ok) {
      const data = await response.json();

      switch (data.Country) {
        case 'UA':
          return 'uk';
        case 'PL':
          return 'pl';
        default:
          return fallbackLng;
      }
    }
  } catch (e) {
    return fallbackLng;
  }

  return fallbackLng;
};
