import {
  AnimationHideKeyframes,
  AnimationTopHideKeyframes,
} from 'components/global/Animations';
import { device } from 'components/global/MediaQuery';
import { TOOLTIP_REMOVE_TIMEOUT } from 'components/tooltip/utils';

import styled, { css } from 'styled-components/macro';

export const AnimationTopHide = css`
  opacity: 0;
  transition: 1s ease;
  animation: ${AnimationTopHideKeyframes} ${TOOLTIP_REMOVE_TIMEOUT}ms ease;
`;

export const AnimationHide = css`
  opacity: 0;
  transition: 1s ease;
  animation: ${AnimationHideKeyframes} ${TOOLTIP_REMOVE_TIMEOUT}ms ease;
`;

export interface ScTooltipProps {
  warning?: boolean;
  info?: boolean;
  success?: boolean;
  error?: boolean;
  animate?: boolean;
  inline?: boolean;
}

export const ScTooltip = styled.div<ScTooltipProps>`
  position: relative;
  display: flex;
  align-items: center;
  font: ${({ theme }) => theme.typography['label-medium']};
  white-space: pre-wrap;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.primitives['s-12']}
    ${({ theme }) => theme.spacing.primitives['s-16']}
    ${({ theme }) => theme.spacing.primitives['s-12']}
    ${({ theme }) => theme.spacing.primitives['s-48']};
  border-radius: ${({ theme }) => theme.radius['radius-pr-strong']};
  color: ${({ theme }) => theme.colors['text-primary-inverted']};
  text-transform: none;
  background: url(${({ info, error, warning, success, theme }) =>
      info
        ? theme.infoIcon
        : error
          ? theme.errorIcon
          : warning
            ? theme.warningIcon
            : success
              ? theme.successIcon
              : ''})
    no-repeat ${({ theme }) => theme.spacing.primitives['s-16']};
  background-color: ${({ theme }) => theme.colors['background-01-inverted']};
  border: 1px solid
    ${({ info, error, warning, success, theme }) =>
      info
        ? theme.colors['border-info']
        : error
          ? theme.colors['border-danger']
          : warning
            ? theme.colors['border-warning']
            : success
              ? theme.colors['border-success']
              : ''};
`;

export const ScTooltipGlobal = styled(ScTooltip)`
  position: fixed;
  bottom: 20px;
  right: 10px;
  left: auto;
  top: auto;
  z-index: 1111;
  ${({ animate }) => (animate ? AnimationHide : null)};
  &:before {
    @media (${device.desktop}) {
      border-radius: 0;
    }
  }
  @media (${device.desktop}) {
    ${({ animate }) => (animate ? AnimationTopHide : null)};
    border-radius: 0;
    bottom: auto;
    right: auto;
    top: 0;
    left: 0;
    width: 100vw;
  }
`;
