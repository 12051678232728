import { factory } from 'api/base';

import { getResponseCamelization } from 'helpers/api';
import { snakify } from 'helpers/common';
import { Permission, RecaptchaVersion } from 'helpers/enums';

export interface Recaptcha {
  'g-recaptcha-response': string;
  recaptchaVersion: RecaptchaVersion;
}

export interface Account {
  id: number;
  email: string;
  permissions: { [T in Permission]: boolean };
  timezone: string;
}

export interface LoginCredentials extends Recaptcha {
  email: string;
  password: string;
}

export const isAuthenticated = factory((r) => () => r<boolean>('get', '/auth'));

export const account = factory(
  (r) => () =>
    r<Account>('get', '/account', undefined, {
      transformResponse: getResponseCamelization([['data', 'permissions']]),
    })
);

export const login = factory(
  (r) => (credentials: LoginCredentials) =>
    r<null>('post', '/login', snakify(credentials))
);

export const logout = factory((r) => () => r<null>('post', '/logout', {}));
