import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import { env } from 'helpers/env';
import { getMirroringConfigWithDefault } from 'helpers/mirroring';

import * as Styled from './ForbiddenPage.styled';

export const ForbiddenPage = ({
  ip,
  countryCode,
}: {
  ip: string;
  countryCode: string;
}) => {
  const { images } = useTheme();
  const { t } = useTranslation();

  return (
    <Styled.ForbiddenBlock>
      <Styled.ForbiddenHeader>
        <img
          src={getMirroringConfigWithDefault('logoDesktop', images.logo.path)}
          alt=""
        />
      </Styled.ForbiddenHeader>
      <Styled.ForbiddenBody>
        <Styled.ForbiddenIcon>
          <images.accessDeniedIcon.component />
        </Styled.ForbiddenIcon>

        <Styled.ForbiddenTitle>
          {t('common:forbiddenError.title')}
        </Styled.ForbiddenTitle>
        <Styled.ForbiddenSubtitle>
          {t('common:forbiddenError.subtitle')}{' '}
          {env.REACT_APP_SUPPORT_MAIL ? (
            <a href={`mailto:${env.REACT_APP_SUPPORT_MAIL}`}>
              {env.REACT_APP_SUPPORT_MAIL}
            </a>
          ) : (
            ''
          )}
        </Styled.ForbiddenSubtitle>
        <Styled.ForbiddenInfo>
          <p>
            {t('common:forbiddenError.country')}: <span>{countryCode}</span>
          </p>
          <p>
            {t('common:forbiddenError.ip')}: <span>{ip}</span>
          </p>
        </Styled.ForbiddenInfo>
      </Styled.ForbiddenBody>
    </Styled.ForbiddenBlock>
  );
};
